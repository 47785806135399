/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HvTabs, HvTab, HvGrid } from '@hitachivantara/uikit-react-core';
import MicroSCADATable from './components/MicroSCADATable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './AssetInventory.scss';
import PageHeader from '../../Components/PageHeader';
import { PageHeaderText } from '../../const/appConstants';

const AssetInventory = () => {
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const type = search.get('type');
  const productRoutes = useMemo(() => {
    return ['microscada', 'ied', 'rtu'];
  }, []);
  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, tabNumber: number) => {
      setTabValue(tabNumber);
      navigate(`/asset?type=${productRoutes[tabNumber]}`);
    },
    [navigate, productRoutes]
  );

  useEffect(() => {
    setTabValue(productRoutes.findIndex((item: string) => item === type));
  }, [type, productRoutes]);

  return (
    <>
      <HvGrid
        container
        spacing={3}
      >
        <PageHeader text={PageHeaderText.AssetsPageHeader} />
        <HvGrid
          item
          xs={12}
        >
          <HvTabs
            onChange={handleTabChange}
            value={tabValue}
            className='asset-tabs'
            id='asset-tabs'
            data-testid='asset-tabs'
          >
            <HvTab
              id='microscada-tab'
              label='MicroSCADA'
              data-testid='asset-tabs-microSCADA'
            />
            <HvTab
              id='ied-tab'
              label='IED'
              disabled
            />
            <HvTab
              id='rtu-tab'
              label='RTU'
              disabled
            />
          </HvTabs>
        </HvGrid>
        <HvGrid
          item
          xs={12}
        >
          {/* Future Development for developing different component */}
          <MicroSCADATable />
        </HvGrid>
      </HvGrid>
    </>
  );
};

export default AssetInventory;
