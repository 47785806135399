/// Copyright 2024 Hitachi Energy. All rights reserved.

import {
  HvDropDownMenu,
  HvGrid,
  HvLink
} from '@hitachivantara/uikit-react-core';
import React, { useCallback } from 'react';
import './UserInformation.scss';
import { User } from '@hitachivantara/uikit-react-icons';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';

const UserInformation = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});
  const username = account?.username;

  const handleLogout = useCallback(() => {
    instance.logout();
  }, [instance]);

  const handleLogin = useCallback(() => {
    instance.loginRedirect();
  }, [instance]);

  return (
    <HvGrid container>
      <HvGrid
        item
        xs={12}
      >
        <div
          id='homepage-informationicon-div'
          className='header-version'
        >
          <HvDropDownMenu
            id='homepage-informationicon-dropdown'
            classes={{
              baseContainer: 'version-listcontainer'
            }}
            keepOpened={false}
            dataList={[
              {label : isAuthenticated && username},
              isAuthenticated
                ? { label: <HvLink onClick={handleLogout}>Logout</HvLink> }
                : { label: <HvLink onClick={handleLogin}>Login</HvLink> }
            ]}
            variant='primaryGhost'
            icon={<User />}
          />
        </div>
      </HvGrid>
    </HvGrid>
  );
};

export default UserInformation;
