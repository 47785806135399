/// Copyright 2024 Hitachi Energy. All rights reserved.

import { HvGrid } from '@hitachivantara/uikit-react-core';
import React from 'react';
import './Legend.scss';
import { lifecycleStatus } from '../../const/appConstants';

export const Legend = () => {
  return (
    <HvGrid
      xs={12}
      className='legend-container'
      data-testid='legend-container'
    >
      {lifecycleStatus.map((status) => (
        <HvGrid
          xs={1}
          className='legend-item'
          key={status.label}
        >
          <HvGrid
            className='legend-status-indicator'
            data-testid='legend-status-indicator'
            style={{ backgroundColor: status.color }}
          ></HvGrid>
          <p className='legend-status-name'>{status.label}</p>
        </HvGrid>
      ))}
    </HvGrid>
  );
};
