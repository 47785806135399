/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useCallback, useEffect, useState } from 'react';
import {
  HvDropdown,
  HvGrid,
  HvListValue
} from '@hitachivantara/uikit-react-core';
import { Box } from '@mui/material';
import LifecycleDetails from '../LifecycleDetails/LifecycleDetails';
import {
  getLifecycleProductSeries,
  getLifecycleProductName,
  getLifecycleProductVersion
} from '../../service/lifecycleDetailsService';
import { generateDropdownOptions } from '../../utils/utils';
import { StatusCode, PageHeaderText } from '../../const/appConstants';
import PageHeader from '../../Components/PageHeader';

type Options = HvListValue[] | HvListValue | undefined;
interface DropdownOption {
  label: string;
  value: string;
  isHidden: boolean;
  selected: boolean;
}

const Lifecycle = () => {
  const [productSeries, setProductSeries] = useState<DropdownOption[]>([]);
  const [productNames, setProducNames] = useState<DropdownOption[]>([]);
  const [productVersions, setProductVersions] = useState<DropdownOption[]>([]);
  const [selectedProductSeries, setSelectedProductSeries] = useState<string[]>(
    []
  );
  const [selectedProductNames, setSelectedProductNames] = useState<string[]>(
    []
  );
  const [selectedProductVersion, setSelectedProductVersion] = useState<
    string[]
  >([]);

  const fetchProductSeries = async () => {
    const response = await getLifecycleProductSeries();
    if (response.status === StatusCode.success) {
      setProductSeries(generateDropdownOptions(response.data.data));
    }
  };

  const fetchProductNames = async (productSeriesList: string[]) => {
    const response = await getLifecycleProductName(productSeriesList);
    if (response.status === StatusCode.success) {
      setProducNames(generateDropdownOptions(response.data.data));
    }
  };

  const fetchProductVersions = async (
    productSeriesArray: string[],
    productNamesArray: string[]
  ) => {
    const response = await getLifecycleProductVersion(
      productSeriesArray,
      productNamesArray
    );
    if (response.status === StatusCode.success) {
      setProductVersions(generateDropdownOptions(response.data.data));
    }
  };

  useEffect(() => {
    fetchProductSeries();
  }, []);

  const handleHubNameChange = useCallback(() => {
    //this has to be taken care after implementation of Authorization
  }, []);

  const handleSiteNameChange = useCallback(() => {
    //this has to be taken care after implementation of Authorization
  }, []);

  const handleSiteCountryChange = useCallback(() => {
    //this has to be taken care after implementation of Authorization
  }, []);

  const handleProductSeriesChange = useCallback(
    (selectedOptions: HvListValue[] | HvListValue | undefined) => {
      const selectedProductSeriesFromDropdown = selectedOptions?.map(
        (item: DropdownOption) => item.value
      );
      setSelectedProductSeries(selectedProductSeriesFromDropdown);
      fetchProductNames(selectedProductSeriesFromDropdown);
      setProductVersions(generateDropdownOptions([]));
      setSelectedProductNames([]);
      setSelectedProductVersion([]);
    },
    []
  );

  const handleProductNameChange = useCallback(
    (selectedOptions: Options) => {
      const selectedProductNameFromDropdown = selectedOptions?.map(
        (item: DropdownOption) => item.value
      );
      setSelectedProductNames(selectedProductNameFromDropdown);
      setSelectedProductVersion([]);
      fetchProductVersions(
        selectedProductSeries,
        selectedProductNameFromDropdown
      );
    },
    [selectedProductSeries]
  );

  const handleProductVersionChange = useCallback((selectedOptions: Options) => {
    const selectedProductVersionFromDropdown = selectedOptions?.map(
      (item: DropdownOption) => item.value
    );
    setSelectedProductVersion(selectedProductVersionFromDropdown);
  }, []);

  return (
    <Box>
      <HvGrid
        container
        spacing={3}
      >
        <PageHeader text={PageHeaderText.LifecycleDatailsHeader} />
        <HvGrid
          item
          xs={2}
        >
          <HvDropdown
            id='lifecycle-hubName'
            data-testid='lifecycle-hubName'
            disablePortal={true}
            multiSelect={true}
            values={[]}
            disabled={true}
            statusMessage='Hub cannot be blank!'
            onChange={handleHubNameChange}
            showSearch
            height={150}
            label='Hub Name'
            placeholder='Select Hub Name'
          />
        </HvGrid>
        <HvGrid
          item
          xs={2}
        >
          <HvDropdown
            id='lifecycle-site-name'
            data-testid='lifecycle-site-name'
            disablePortal={true}
            multiSelect={true}
            values={[]}
            statusMessage='Site cannot be blank!'
            onChange={handleSiteNameChange}
            showSearch
            disabled={true}
            height={150}
            label='Site'
            placeholder='Select Site'
          />
        </HvGrid>
        <HvGrid
          item
          xs={2}
        >
          <HvDropdown
            id='lifecycle-site-country'
            data-testid='lifecycle-site-country'
            disablePortal={true}
            multiSelect={true}
            values={[]}
            statusMessage='Site country cannot be blank!'
            onChange={handleSiteCountryChange}
            showSearch
            height={150}
            disabled={true}
            label='Site Country'
            placeholder='Select Site Country'
          />
        </HvGrid>
        <HvGrid
          item
          xs={2}
        >
          <HvDropdown
            id='lifecycle-product-series'
            data-testid='lifecycle-product-series'
            aria-label='Single selection'
            disablePortal={true}
            multiSelect={true}
            values={productSeries}
            statusMessage='Product series cannot be blank!'
            onChange={handleProductSeriesChange}
            showSearch
            height={150}
            label='Product Series'
            placeholder='Select product series'
          />
        </HvGrid>
        <HvGrid
          item
          xs={2}
        >
          <HvDropdown
            id='lifecycle-product-name'
            data-testid='lifecycle-product-name'
            // aria-label='Single selection'
            disablePortal={true}
            multiSelect={true}
            values={productNames}
            disabled={productNames?.length === 0}
            statusMessage='Product name cannot be blank!'
            onChange={handleProductNameChange}
            showSearch
            height={150}
            label='Product Name'
            placeholder='Select product name'
          />
        </HvGrid>
        <HvGrid
          item
          xs={2}
        >
          <HvDropdown
            id='lifecycle-product-version'
            data-testid='lifecycle-product-version'
            aria-label='Single selection'
            disablePortal={true}
            multiSelect={true}
            values={productVersions}
            disabled={productVersions?.length === 0}
            statusMessage='Product version cannot be blank!'
            onChange={handleProductVersionChange}
            showSearch
            height={150}
            label='Product Version'
            placeholder='Select product version'
          />
        </HvGrid>
      </HvGrid>
      <LifecycleDetails
        productSeriesSelected={selectedProductSeries}
        productNamesSelected={selectedProductNames}
        productVersionsSelected={selectedProductVersion}
      />
    </Box>
  );
};

export default Lifecycle;
