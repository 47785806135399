/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useCallback } from 'react';
import {
  HvButton,
  HvFooter,
  HvGrid,
  HvTypography
} from '@hitachivantara/uikit-react-core';
import { useNavigate } from 'react-router-dom';
import hitachiInspireNextLogo from '../../Components/assets/CS_Logo_FullColor_RGB.svg';
import { CoreRoutes } from '../../const/appConstants';
import './Login.scss';
import { useIsAuthenticated } from '@azure/msal-react';
import LoginPageImage from '../../assets/images/LoginImage.png';
import hitachiEnergyLogo from '../../assets/hitachi-energy-mark-red.svg';

const Login = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const handleSignInButtonClick = useCallback(() => {
    const handleLogin = async () => {
      if (isAuthenticated) {
        navigate(CoreRoutes.home);
        return;
      }
      navigate(CoreRoutes.homeAuth);
    };

    handleLogin();
  }, [navigate, isAuthenticated]);

  return (
    <HvGrid className='login-main-container'>
      <HvGrid
        container
        direction='row'
        style={{ height: '100%' }}
      >
        <HvGrid
          item
          xs={8}
          style={{ height: '100%' }}
        >
          <img
            src={LoginPageImage}
            alt={LoginPageImage}
            className="login-image"
          />
        </HvGrid>
        <HvGrid
          item
          xs={4}
          direction='column'
          style={{ paddingLeft: '0px' }}
        >
          <HvGrid className='login-pageDetails'>
            <HvTypography className='login-project-name'>
              Asset Health Guardian
            </HvTypography>
          </HvGrid>
          <HvGrid>
            <HvTypography className='login-with-SAML-SSO login-pageDetails'>
              Log in with SAML SSO
            </HvTypography>
          </HvGrid>
          <HvGrid className='login-pageDetails'>
            <HvButton
              className='log-in-button'
              variant='primary'
              onClick={handleSignInButtonClick}
              data-testid='log-in-button'
            >
              Log in with SSO
            </HvButton>
          </HvGrid>
          <HvGrid className='login-pageDetails'>
            <img
              className='hitachi-inspire-logo'
              src={hitachiInspireNextLogo}
              alt={hitachiInspireNextLogo}
            />
          </HvGrid>
          <HvGrid className='login-pageDetails'>
            <img
              className='hitachi-logo'
              src={hitachiEnergyLogo}
              alt={hitachiEnergyLogo}
            />
          </HvGrid>
        </HvGrid>
      </HvGrid>
      <HvFooter
        className='footer'
        name='Hitachi Energy'
        copyright='© Hitachi Energy Corporation 2021. All Rights Reserved.'
        links='Licence Information'
      ></HvFooter>
    </HvGrid>
  );
};

export default Login;
