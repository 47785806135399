/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useEffect, useState } from 'react';
import {
  HvDialog,
  HvDialogContent,
  HvDialogTitle,
  HvSection,
  HvTypography
} from '@hitachivantara/uikit-react-core';
import { getUpgradeRecommendation } from '../../service/lifecycleDetailsService';
import './RecommendationDialog.scss';

interface RecommendationDialogProps {
  open: boolean;
  productName: string;
  onClose: () => void;
}

const RecommendationDialog: React.FC<RecommendationDialogProps> = ({
  open,
  productName,
  onClose
}) => {
  const [upgradeProduct, setUpgradeProduct] = useState('');
  const [upgradeOperatingSystem, setUpgradeOperatingSystem] = useState([]);

  useEffect(() => {
    const fetchUpgradeData = async () => {
      const response = await getUpgradeRecommendation(productName);
      if (response) {
        const { productRecommendation, operatingSystemRecommendation } =
          response.data.data;
        setUpgradeProduct(productRecommendation);
        setUpgradeOperatingSystem(operatingSystemRecommendation);
      }
    };
    fetchUpgradeData();
  }, [productName]);

  return (
    <HvDialog
      open={open}
      onClose={onClose}
    >
      <HvDialogTitle>Upgrade Recommendations</HvDialogTitle>
      <HvDialogContent
        indentContent
        className='recommendation-dialog'
      >
        <HvSection
          className='recommedation-dialog-section'
          title={
            <HvTypography
              variant='label'
              className='recommendation-hypography'
            >
              Product Update
            </HvTypography>
          }
        >
          <HvTypography
            variant='body'
            className='recommendation-hypography'
          >
            {upgradeProduct ?? 'Recommendation Not Available'}
          </HvTypography>
        </HvSection>
        <HvSection
          className='recommedation-dialog-section'
          title={
            <HvTypography
              variant='label'
              className='recommendation-hypography'
            >
              Operating System
            </HvTypography>
          }
        >
          {upgradeOperatingSystem.length > 0
            ? upgradeOperatingSystem.map((item) => (
                <HvTypography
                  key={item}
                  variant='body'
                  className='recommendation-hypography'
                >
                  {item}
                </HvTypography>
              ))
            : 'Recommendation Not Available'}
        </HvSection>
      </HvDialogContent>
    </HvDialog>
  );
};

export default RecommendationDialog;
