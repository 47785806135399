/// Copyright 2024 Hitachi Energy. All rights reserved.

import { HvBox } from '@hitachivantara/uikit-react-core';
import React from 'react';

const colorSchema = {
  active: '#99AC3C',
  classic: '#FFD86C',
  limited: '#BA4E56',
  obsolete: '#5373C2'
};

type status = 'active' | 'classic' | 'limited' | 'obsolete';

interface TColorBox {
  status: status;
}

export const ColorBox = (props: TColorBox) => {
  const { status } = props;
  return (
    <HvBox
      role='box'
      sx={{
        backgroundColor: colorSchema[status],
        width: '100%',
        height: '24px'
      }}
    ></HvBox>
  );
};
