/// Copyright 2024 Hitachi Energy. All rights reserved.

import axios from 'axios';
import {authParameters, getMsalInstance}  from '../Components/authentication/AuthProvider';
import {
  PublicClientApplication,
  RedirectRequest
} from '@azure/msal-browser';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});
axiosInstance.interceptors.request.use(async function (config) {
  const instance = await getMsalInstance();
  const authRequest = {
    ...authParameters,
    account: instance.getAllAccounts()[0]
  };

  if (!authRequest.account.idTokenClaims?.exp || isTokenExpired(authRequest.account.idTokenClaims?.exp)) {
    await redirectToLogin(instance);
    return config;
  }

  const result = await instance.acquireTokenSilent(authRequest);
  config.headers!.Authorization = `Bearer ${result.accessToken}`;
    
  return config;  
});

const isTokenExpired = (expiresOn: number): boolean => {
  const now = new Date();
  const expDate = new Date(0);
  expDate.setUTCSeconds(expiresOn, 10);
  return expDate <= now;
};

const redirectToLogin = async (instance: PublicClientApplication) => {
  const loginRequest: RedirectRequest = {
    ...authParameters,
    prompt: 'login'
  };

  await instance.loginRedirect(loginRequest);
};