/// Copyright 2024 Hitachi Energy. All rights reserved.

import { StatusCode } from '../const/appConstants';
import { generateQueryString } from '../utils/utils';
import { axiosInstance } from './axios';

export const getLifecycleDetails = async (
  productSeries: string[],
  productNames: string[],
  productVersions: string[]
) => {
  try {
    const response = await axiosInstance.get(
      `/Lifecycle/productSeries/productNames/productVersions?${generateQueryString(
        {
          productSeries,
          productNames,
          productVersions
        }
      )}`
    );
    return Promise.resolve({
      data: response.data,
      status: StatusCode.success
    });
  } catch (error) {
    return Promise.resolve({
      status: StatusCode.failed,
      data: error
    });
  }
};

export const getLifecycleProductSeries = async () => {
  try {
    const response = await axiosInstance.get(`/Product`);
    return Promise.resolve({
      data: response.data,
      status: StatusCode.success
    });
  } catch (error) {
    return Promise.resolve({
      status: StatusCode.failed,
      data: error
    });
  }
};

export const getLifecycleProductName = async (productSeries: string[]) => {
  try {
    const response = await axiosInstance.get(
      `/Product/productSeries?${generateQueryString({ productSeries })}`
    );
    return Promise.resolve({
      data: response.data,
      status: StatusCode.success
    });
  } catch (error) {
    return Promise.resolve({
      status: StatusCode.failed,
      data: error
    });
  }
};

export const getLifecycleProductVersion = async (
  productSeries: string[],
  productNames: string[]
) => {
  try {
    const response = await axiosInstance.get(
      `/Product/productSeries/productNames?${generateQueryString({
        productSeries,
        productNames
      })}`
    );
    return Promise.resolve({
      data: response.data,
      status: StatusCode.success
    });
  } catch (error) {
    return Promise.resolve({
      status: StatusCode.failed,
      data: error
    });
  }
};

export const getUpgradeRecommendation = async (productName: string) => {
  try {
    const response = await axiosInstance.get(`/Lifecycle/${productName}`);
    return Promise.resolve({
      data: response.data,
      status: StatusCode.success
    });
  } catch (error) {
    return Promise.resolve({
      status: StatusCode.failed,
      data: error
    });
  }
};
