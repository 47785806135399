/// Copyright 2024 Hitachi Energy. All rights reserved.

import React from 'react';
import { HvGrid, HvPagination } from '@hitachivantara/uikit-react-core';
import './Pagination.scss';

export interface PaginationProps {
  dataCount: number;
  page: number;
  pageSize: number;
  onPageChange: (value: number) => void;
}
const Pagination = ({
  dataCount,
  page,
  pageSize,
  onPageChange
}: PaginationProps) => {
  return (
    <HvGrid
      container
      id='Common-Pagination-container'
      data-testid='Common-Pagination-container'
      direction='column'
      justifyContent='center'
      alignContent='center'
    >
      {Math.ceil(dataCount / pageSize) >= 1 ? (
        <HvPagination
          id='Common-Pagination'
          data-testid='Common-Pagination'
          pages={Math.ceil(dataCount / pageSize)}
          page={page}
          canPrevious={page > 0}
          canNext={
            Math.ceil(dataCount / pageSize)
              ? page < Math.ceil(dataCount / pageSize) - 1
              : false
          }
          pageSize={pageSize}
          onPageChange={onPageChange}
          showPageSizeOptions={false}
        />
      ) : (
        ''
      )}
    </HvGrid>
  );
};
export default Pagination;
