/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useContext, ReactNode, useReducer, useMemo } from 'react';
import { AppState, reducer } from '../AppState/reducers';
import { Actions } from '../AppState/actions';

export interface RootContextProps {
  children?: ReactNode;
}

export interface DefaultContextState {
  dispatch: React.Dispatch<Actions>;
  state: AppState;
}

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext error');
  }
  return context;
};

export const AppContext = React.createContext({} as DefaultContextState);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }: RootContextProps) => {
  const [state, dispatch] = useReducer(reducer, {auth: null});

  const defaultContext = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <AppContext.Provider value={defaultContext}>
      {children}
    </AppContext.Provider>
  );
};
