/// Copyright 2024 Hitachi Energy. All rights reserved.

import React from 'react';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { authConfig } from './Components/authentication/AuthProvider';
import { PublicClientApplication } from '@azure/msal-browser';
import { createRoot } from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { HvProvider } from '@hitachivantara/uikit-react-core';
import AppContext from './Components/authentication/AppContext';

const msalInstance = new PublicClientApplication(authConfig);

msalInstance.initialize().then(() => {
  const root = createRoot(document.getElementById('root') as HTMLElement);
  root.render(
  <React.StrictMode>
    <HvProvider>
      <AppContext>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </AppContext>
    </HvProvider>
  </React.StrictMode>
);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
