/// Copyright 2024 Hitachi Energy. All rights reserved.
import React from 'react';
const Dashboard = () => {
  return (
    <div>
      <h1>Dashboard</h1>
    </div>
  );
};

export default Dashboard;
