/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useEffect, useState, useCallback } from 'react';
import {
  HvGrid,
  HvLoading,
  HvTable,
  HvTableBody,
  HvTableCell,
  HvTableContainer,
  HvTableHead,
  HvTableHeader,
  HvTableProps,
  HvTableRow
} from '@hitachivantara/uikit-react-core';
import { getMicroSCADAData } from '../../../service/assetInventoryService';
import Pagination from '../../../Components/Common/Pagination';
import {TableRowCount} from '../../../const/appConstants'

interface MicroScadaDataType {
  systemId: string;
  siteId: string;
  computerId: string | null;
  computerName: string | null;
  operatingSystemName: string | null;
  ipAddress: string | null;
  productSeries: string | null;
  productName: string | null;
  productVersion: string | null;
  productLifeCycle: string | null;
  siteOperatorGuid: string | null;
}

const colDef = [
  {
    headerText: 'System ID',
    accessor: 'systemId',
    cellType: 'alpha-numeric',
    fixed: 'left'
  },
  {
    headerText: 'Computer Name',
    accessor: 'computerName',
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Product Series',
    accessor: 'productSeries',
    style: { textTransform: 'capitalize' },
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Product Name',
    accessor: 'productName',
    cellType: 'numeric'
  },
  {
    headerText: 'Product Version',
    accessor: 'productVersion',
    style: { textTransform: 'capitalize' },
    cellType: 'alpha-numeric',
    sortable: false
  },
  {
    headerText: 'IP Address',
    accessor: 'ipAddress',
    style: { textTransform: 'capitalize' },
    cellType: 'alpha-numeric'
  },
  {
    headerText: 'Operating System',
    accessor: 'operatingSystemName',
    cellType: 'link',
    fixed: 'right',
    sortable: false
  },
  {
    headerText: 'Product lifecycle',
    accessor: 'productLifeCycle',
    cellType: 'link',
    fixed: 'right',
    sortable: false
  }
];

// props is only used for storybook purposes
const MicroSCADATable = (props: Omit<HvTableProps, 'children'>) => {
  const columns = colDef;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<MicroScadaDataType[]>();
  const customerGuid = 'G06361591';
  const pageSize = TableRowCount.DefaultTableRowCount;
  const [page, setPage] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  const fetchMiccroSCADAData = useCallback(async () => {
    setIsLoading(true);
    const response = await getMicroSCADAData(customerGuid, (page+1), pageSize, '', '', '');
    if (response) {
      const responseData = response?.data?.data?.items;
      setData(responseData);
      setDataCount(response?.data?.data?.totalAssetsCount)
    }
    setIsLoading(false);
  },[page, pageSize]);

  useEffect(() => {
    fetchMiccroSCADAData();
  }, [fetchMiccroSCADAData]);

  const OnTablePageChange = useCallback(
    (value: number) => {
      setPage(value);
      if(value !== page){
        setData([]);
      }
    },
    [page]
  );
  return (
    <HvGrid>
      <HvTableContainer
        data-testid='micro-scada-table-container'
        className='micro-scada-table-wrapper'
      >
        <HvTable
          {...props}
          data-testid='micro-scada-table'
          id='micro-scada-table'
        >
          <HvTableHead>
            <HvTableRow>
              {columns.map((el) => (
                <HvTableHeader key={el.headerText}>
                  {el.headerText}
                </HvTableHeader>
              ))}
            </HvTableRow>
          </HvTableHead>
          <HvTableBody>
            {data &&
              Boolean(data.length) &&
              data.map((el: MicroScadaDataType) => (
                <HvTableRow
                  key={el.systemId}
                  hover
                  striped
                >
                  <HvTableCell>{el.systemId}</HvTableCell>
                  <HvTableCell>{el.computerName}</HvTableCell>
                  <HvTableCell>{el.productSeries}</HvTableCell>
                  <HvTableCell>{el.productName}</HvTableCell>
                  <HvTableCell>{el.productVersion}</HvTableCell>
                  <HvTableCell>{el.ipAddress}</HvTableCell>
                  <HvTableCell>{el.operatingSystemName}</HvTableCell>
                  <HvTableCell>{el.productLifeCycle}</HvTableCell>
                </HvTableRow>
              ))}
            {isLoading && (
              <HvTableRow
                key='Loading'
                hover
                striped
              >
                <HvTableCell colSpan={8}>
                  <HvLoading
                    id='micro-scada-table-data-loading'
                    data-testid='micro-scada-table-data-loading'
                    small
                    label='Fetching MircoSCADA Data'
                  />
                </HvTableCell>
              </HvTableRow>
            )}
          </HvTableBody>
        </HvTable>
        <Pagination
        page={page}
        pageSize={pageSize}
        onPageChange={OnTablePageChange}
        dataCount={dataCount}
        ></Pagination>
      </HvTableContainer>
    </HvGrid>
  );
};

export default MicroSCADATable;
