/// Copyright 2024 Hitachi Energy. All rights reserved.

import { StatusCode } from '../const/appConstants';
import { axiosInstance } from './axios';

export const getMicroSCADAData = async (
  guid: string,
  pageIndex: number,
  pageSize: number,
  column?: string,
  sortOrder?: string,
  searchText?: string
) => {
  try {
    const params: { column?: string; sortOrder?: string; searchText?: string } =
      {};
    if (column) params.column = column;
    if (searchText) params.searchText = searchText;
    if (sortOrder) params.sortOrder = sortOrder;
    const response = await axiosInstance.get(
      `/Microscada/${guid}/${pageIndex}/${pageSize}`,
      { params }
    );
    return Promise.resolve({
      data: response.data,
      status: StatusCode.success
    });
  } catch (error) {
    return Promise.resolve({
      status: StatusCode.failed,
      data: error
    });
  }
};
