/// Copyright 2024 Hitachi Energy. All rights reserved.

import React from 'react';
import { HvGrid, HvTypography } from '@hitachivantara/uikit-react-core';
import './PageHeader.scss';

const PageHeader = ({ text }: { text: string }) => {
  return (
    <HvGrid
      container
      spacing={2}
      className='header-section'
    >
      <HvGrid
        item
        xs={6}
      >
        <HvTypography
          id='lifecycle-title-typography'
          data-testid='lifecycle-title-typography'
          variant='title2'
        >
          {text}
        </HvTypography>
      </HvGrid>
      <HvGrid
        item
        xs={6}
      >
        <HvTypography
          data-testid='pageHeader-dataClassificationText'
          variant='caption1'
          style={{ textAlign: 'right' }}
        >
          Data classification
        </HvTypography>
        <HvTypography
          data-testid='pageHeader-confidentialText'
          variant='captionLabel'
          style={{ textAlign: 'right' }}
        >
          Confidential
        </HvTypography>
      </HvGrid>
    </HvGrid>
  );
};

export default PageHeader;
