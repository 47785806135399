/// Copyright 2024 Hitachi Energy. All rights reserved.

interface DropdownOption {
  label: string;
  value: string;
  isHidden: boolean;
  selected: boolean;
}

export const generateQueryString = (paramsObject: {
  [key: string]: string[] | string;
}) => {
  const params = new URLSearchParams();

  Object.keys(paramsObject).forEach((key) => {
    const values = paramsObject[key];
    if (Array.isArray(values)) {
      values.forEach((value) => {
        params.append(key, value);
      });
    } else {
      params.append(key, values);
    }
  });

  return params.toString();
};

export const generateDropdownOptions = (data: string[]): DropdownOption[] => {
  if (data.length === 0) {
    return [];
  }
  return data.map((item) => ({
    label: item,
    value: item,
    isHidden: false,
    selected: false
  }));
};
