/// Copyright 2024 Hitachi Energy. All rights reserved.

import { Actions } from './actions';

// Define the types for State and Action
type AppState = {
  auth: any;
};

// Initial state
const initialState: AppState = {
  auth: []
};

// Reducer function
const reducer = (state: AppState, action: Actions): AppState => {
  switch (action.type) {
    case 'SET_AUTHENTICATION':
      return { ...state, auth: action.payload };
    case 'SET_LOGOUT':
      return { ...state, auth: null };
    default:
      return state;
  }
};

export { AppState, initialState, reducer };
