/// Copyright 2024 Hitachi Energy. All rights reserved.

import { HvButton, HvGrid, HvLoading } from '@hitachivantara/uikit-react-core';
import { Level3Bad } from '@hitachivantara/uikit-react-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthPage.scss';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

interface AuthPageError {
  title: string;
  description?: JSX.Element;
}

const AuthPage = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [error, setError] = useState<AuthPageError | undefined>(undefined);
  const [statusMessage, setStatusMessage] = useState<string>(
    'Check session status...'
  );
  const navigate = useNavigate();

  const renderError = () => (
    <div className='errorContainer'>
      <Level3Bad color='red' />
      <p style={{ fontWeight: 'bold' }}>{error?.title}</p>
      {error?.description ?? <></>}
    </div>
  );

  const handleLogin = useCallback(async () => {
    setStatusMessage('Wait for Microsoft authentication response...');
    await instance.loginRedirect();
  }, [instance]);

  const handleLogout = useCallback(() => instance.logoutRedirect(), [instance]);

  const checkAuthorization = useCallback(async () => {
    try {
      if (accounts && accounts.length > 0) {
        navigate('/');
      } else {
        setError({
          title: 'Authorization Failed',
          description: (
            <>
              <p style={{ paddingBottom: '16px' }}>
                You are not authorized to use this application.
              </p>
              <HvButton onClick={handleLogout}>Sign out</HvButton>
            </>
          )
        });
      }
    } catch (authorizationError) {
      setError({
        title: 'Authorization Error',
        description: (
          <p>
            An error occurred while checking authorization. Please try again.
          </p>
        )
      });
    }
  }, [accounts, navigate, handleLogout]);

  useEffect(() => {
    const handleAuthFlow = async () => {
      if (isAuthenticated) {
        await checkAuthorization();
      }
      if (inProgress === InteractionStatus.None) {
        await handleLogin();
      }
    };

    handleAuthFlow().catch(() =>
      setStatusMessage('Wait for Microsoft authentication response...')
    );
  }, [isAuthenticated, inProgress, checkAuthorization, handleLogin]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return (
    <HvGrid
      container
      spacing={0}
      justifyContent='center'
      id='Pages-AuthPage'
    >
      <HvGrid
        item
        xs={10}
        sm={6}
        md={3}
        className='authPageWrapper'
      >
        {error ? (
          renderError()
        ) : (
          <HvLoading
            label={statusMessage}
            small
          />
        )}
      </HvGrid>
    </HvGrid>
  );
};

export default AuthPage;
