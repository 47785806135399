/// Copyright 2024 Hitachi Energy. All rights reserved.

export enum StatusCode {
  success = 'success',
  failed = 'failed'
}

export enum PageHeaderText {
  AssetsPageHeader = 'Assets',
  LifecycleDatailsHeader = 'MicroSCADA Product Lifecycle'
}

export const lifecycleStatus = [
  { label: 'Active', color: '#99AC3C' },
  { label: 'Classic', color: '#FFD86C' },
  { label: 'Limited', color: '#BA4E56' },
  { label: 'Obsolete', color: '#5373C2' }
];
export const SessionTimeout = {
  Max_Value: 60000
};

export const AHGDomains = {
  dev: 'dev.ahg.hitachienergy.com',
  test: 'test.ahg.hitachienergy.com',
  prod: 'ahg.hitachienergy.com'
};

export const CoreRoutes = {
  home: '/',
  homeAuth: '/auth',
  login: '/login',
  asset: '/asset',
  lifecycle: '/lifecycle',
  logout: '/logout'
};

export const SetTimeoutValue = {
  AuthExecuteDelayed: 1000,
  AuthLoginDelayed: 100,
};

export const TableRowCount = {
  DefaultTableRowCount: 10
};